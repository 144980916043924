/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { deleteAdminAccount, fetchAdminAccountList } from './asyncThunk';

const initialState = {
  currentPage: 1,
  pageSize: 10,
  accountList: [],
  selectedList: [],
  totalPages: 0,
  search: "",
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: "accountList",
  initialState,
  reducers: {
    setCurrentPage:{
      reducer(state, action){
        state.currentPage = action.payload.currentPage
      }
    },
    setPageSize:{
      reducer(state, action){
        state.pageSize = Number(action.payload.pageSize)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminAccountList.pending, (state, _action) => {
      state.isLoading = true;
    })
    builder.addCase(fetchAdminAccountList.fulfilled, (state, action) => {
      state.currentPage = action.payload.current_page
      state.pageSize = Number(action.payload.per_page)
      state.totalPages = action.payload.last_page
      state.accountList = action.payload.data
      state.isLoading = false
    }),
    builder.addCase(fetchAdminAccountList.rejected, (state, _action) => {
      state.userList = []
      state.selectedList = []
      state.error = "Can not fetch Data"
      state.isLoading = false
    })
    builder.addCase(deleteAdminAccount.pending, (state, _action) => {
      state.isLoading = true;
    })
    builder.addCase(deleteAdminAccount.fulfilled, (state, _action) => {
      state.isLoading = false;
    })
    builder.addCase(deleteAdminAccount.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    })
  }
})

export const accountList = slice.reducer;
export const {setCurrentPage, setPageSize} = slice.actions;
