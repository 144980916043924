import { createSlice } from "@reduxjs/toolkit";

const emptyButton = {
  id: 0,
  button_name: "",
  button_type: "0",
  button_size: 0,
  button_width: 0,
  button_height: 0,
  button_content: "",
  button_icon: "",
  button_position_x: 0,
  button_position_y: 0,
  button_position_z: 0,
  index: -1,
  action: "",
  button_upload_content: '',
  button_upload: ''
}

const initialState = {
  button: emptyButton,
  isEdit: false,
}

const slice = createSlice({
  name: "button",
  initialState,
  reducers: {
    setBtnTempPosition: {
      reducer(state, action) {
        state.tempPosition = action.payload.tempPosition
      }
    },
    setButtonPosition: {
      reducer(state, action) {
        state.newPosition = action.payload.newPosition
      }
    },
    setButtonToEdit: {
      reducer(state, action) {
        state.button = action.payload;
        state.isEdit = action.payload.isEdit;
      }
    },
    resetButtonState: {
      reducer(state, action) {
        return initialState;
      }
    }
  },
  extraReducers: (builder) => {

  }
})

export const buttonToEdit = slice.reducer;

export const { resetButtonState, setButtonPosition, setButtonToEdit, setBtnTempPosition } = slice.actions;