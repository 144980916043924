/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { deleteExhibition, fetchExhibitionsList } from './asyncThunk';

const initialState = {
  pageNum: 0,
  currentPage: 1,
  pageSize: 9,
  exhibitionList:[],
  exhibition:[],
  topPage:[],
  contentPage:[],
  total: 0,
  search: "",
  isLoading: false,
  error: null,
  success: null
}

const slice = createSlice({
  name: "exhibitionList",
  initialState,
  reducers: {
    setCurrentPage:{
      reducer(state, action){
        state.currentPage = action.payload.currentPage
      }
    },
    setPageSize:{
      reducer(state, action){
        state.pageSize = Number(action.payload.pageSize)
      }
    }
  },
  extraReducers: (builder) => {

    // Exhibitions
    builder.addCase(fetchExhibitionsList.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(fetchExhibitionsList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentPage = action.payload.current_page
      state.pageSize = Number(action.payload.per_page)
      state.totalPages = action.payload.last_page
      state.exhibitionList = action.payload;
    })
    builder.addCase(fetchExhibitionsList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    
    // delete exhibition
    builder.addCase(deleteExhibition.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(deleteExhibition.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentPage = action.payload.data.current_page
      state.pageSize = Number(action.payload.data.per_page)
      state.totalPages = action.payload.data.last_page
      state.exhibitionList = action.payload.data;
    })
    builder.addCase(deleteExhibition.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    // *************************
  }
})

export const exhibitionList = slice.reducer;
export const {setCurrentPage, setPageSize} = slice.actions;
