import { createStore, compose, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import { userSignin } from './slice/userSlice/signin-signupSlice';
import { userList } from './slice/userSlice/userListSlice';
import { userDetail } from './slice/userSlice/userDetailSlice';
import { accountList } from './slice/userSlice/adminAccountList';
import { adminAccountEdit } from './slice/userSlice/adminAccountAddEdit';
import { buttonToEdit } from './slice/exhibitionSlice/editButtonSlice'
import { exhibitionAddEdit } from './slice/exhibitionSlice/exhibitionAddEdit';
import { exhibitionView } from './slice/exhibitionSlice/exhibitionView';
import { contentPage } from './slice/exhibitionSlice/contentPageSlice';
import { exhibitionList } from './slice/exhibitionSlice/exhibitionList';
import { templateList } from './slice/adminSlice/templateList';
import { partCategory } from './slice/commonPartSlice/categorySlice';
import { commonPart } from './slice/commonPartSlice/comPartSlice'
import { templateAddEdit } from './slice/adminSlice/templateAddEdit';
import { exhibitionAccess } from './slice/exhibitionSlice/exhibitionAccessSlice';

const reducer = combineReducers({
  userSignin: userSignin,
  // userRegister: ,
  userList: userList,
  userDetail: userDetail,
  accountList: accountList,
  accountAddEdit:adminAccountEdit,
  exhibitionList: exhibitionList,
  exhibitionAddEdit: exhibitionAddEdit,
  exhibitionView: exhibitionView,
  buttonToEdit: buttonToEdit,
  contentPage: contentPage,
  templateList: templateList,
  partCategory: partCategory,
  commonPart: commonPart,
  templateAddEdit: templateAddEdit,
  exhibitionAccess: exhibitionAccess,

  // userDelete: ,
  // userUpdate: ,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  return createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(thunk))
  );
}
