import React from 'react';
import './index.css';

function ThemedSuspense() {
  return (
    <div className="wrap-suspense">
        <div className="loading">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
    </div>
  )
}

export default ThemedSuspense;