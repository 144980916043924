import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import ThemedSuspense from './components/ThemedSuspense/index'

import configureStore from './redux/store';
export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
      <Suspense fallback={<ThemedSuspense />}>
        <App />
      </Suspense>
  </Provider>,
  document.getElementById('root')
);
