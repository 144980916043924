import React from 'react';
import { useSelector } from "react-redux";
import { Link, Redirect, Route } from "react-router-dom";


export const PrivateRoute = ({ component: Component, ...rest }) => {
    const { userInfo } = useSelector(state => state.userSignin);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (userInfo.access_token) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to={{ pathname: "/user/login" }} />;
                }
            }}
        />
    );
};
export const PublicRoute = ({ component: Component, ...rest }) => {
    const { userInfo } = useSelector(state => state.userSignin);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (userInfo.access_token) {
                    if (userInfo.user) {
                        if(userInfo.user.role == 9) {
                            return <Redirect to={{ pathname: "/admin" }} />;
                        }else{
                            return <Redirect to={{ pathname: "/user" }} />;
                        }
                    }
                } else {
                    return <Component {...props} />;
                }
            }}
        />
    );
}
export const AppRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return <Component {...props} />;
            }}
        />
    );
}
