/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { signin, signup, forgotPassword, contact, logout } from './asyncThunk';
import { setCookie, getCookie } from '../../../pages/helper/cookie';

const emptyUser = {
  name: "",
  email: "",
  token: "",
  role: 0,
}

const initialState = {
  // userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : emptyUser,
  userInfo: getCookie('userInfo') ? JSON.parse(getCookie('userInfo')) : emptyUser,
  isLoading: false,
  isCreated: false,
  error: null,
  screen: "input",
  userLoggedIn: null,
  contactSuccess: false,
  message: null,
}

const slice = createSlice({
  name: "userSignin",
  initialState,
  reducers: {
    signout(state, action) {
      switch (state.userInfo.user.role) {
        case 9:
          document.location.replace('/admin/login');
          break;
        default:
          document.location.replace('/user/login');
          break;
      }
      state.userInfo = emptyUser
      // // localStorage.clear();
      setCookie('userInfo', JSON.stringify(emptyUser))
    },
    setScreen(state, action) {
      state.screen = action.payload.screen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signin.pending, (state, action) => {
      state.isLoading = true
    });
    builder.addCase(signin.fulfilled, (state, action) => {
      if (action.payload.status === 400) {
        state.error = action.payload.message
        state.userInfo = action.payload.user
      } else {
        state.isLoading = false
        state.userInfo = action.payload.user
        state.userInfo.token = action.payload.access_token
        state.error = {}
        switch (action.payload.user.role) {
          case 9:
            document.location.href = '/admin/template';
            break;
          default:
            document.location.href = '/user/exhibition';
            break;
        }
        setCookie('userInfo', JSON.stringify(action.payload), action.payload.expires_at);
      }
    });
    builder.addCase(signin.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload.message
    })
    builder.addCase(signup.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(signup.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.success) {
        state.screen = 'completed';
      }
      else if (action.payload.confirm) {
        state.screen = 'confirm';
      }
      state.error = null
    })
    builder.addCase(signup.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload.errors
    })
    builder.addCase(forgotPassword.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.success) {
        state.screen = 'completed';
      }
      else if (action.payload.confirm) {
        state.screen = 'confirm';
      }
      state.error = null
    })
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload.errors
    })

    builder.addCase(contact.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(contact.fulfilled, (state, action) => {
      state.isLoading = false;
      state.contactSuccess = true;
      state.message = action.payload;
      state.error = null
    })
    builder.addCase(contact.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    builder.addCase(logout.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(logout.fulfilled, (state, action) => {
      switch (state.userInfo.user.role) {
        case 9:
          document.location.replace('/admin/login');
          break;
        default:
          document.location.replace('/user/login');
          break;
      }
      state.userInfo = emptyUser
      setCookie('userInfo', JSON.stringify(emptyUser));
    })
    builder.addCase(logout.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload.errors
    })

  }
})

export const userSignin = slice.reducer;

export const { signout, setScreen } = slice.actions;