import { createSlice } from "@reduxjs/toolkit";
import { fetchPageAccess, fetchPageAccessByID } from './asyncThunk';

const initialState = {
  accessRawData: [],
  pageData: [],
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: "exhibitionAccess",
  initialState,
  reducers:{

  },
  extraReducers: (builder) =>{
    builder.addCase(fetchPageAccess.pending, (state) => {
        state.isLoading = true;
    })
    builder.addCase(fetchPageAccess.fulfilled, (state, action) => {
      state.isLoading = false;
      state.accessRawData = action.payload;
    })
    builder.addCase(fetchPageAccess.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    builder.addCase(fetchPageAccessByID.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(fetchPageAccessByID.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pageData = action.payload;
    })
    builder.addCase(fetchPageAccessByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
  }
})

export const exhibitionAccess = slice.reducer;