import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_PREFIX = `${process.env.REACT_APP_PREFIX_URL}`;

/*---------------------Common Part Category----------------------*/ 

function getHeader(token){
  const header = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Authorization": `Bearer ${token && token}`,
    }
  };
  return header;
}

export const fetchPartCategoryList = createAsyncThunk(
    '/common/get',
    async(arg, {getState, rejectWithValue}) => {
      const state = getState();
      const token = state.userSignin.userInfo.user.token;
      const header = getHeader(token);
      return await axios.get(`${API_PREFIX}/api/admin/common/category`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error)=>{
        return rejectWithValue(error.response.data);
      })
    }
)

export const getPartCategoryById = createAsyncThunk(
  'common/category/:id',
  async(categoryId, {getState, rejectWithValue}) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = getHeader(token);
    return await axios.get(`${API_PREFIX}/api/admin/common/category/${categoryId}`,header)
    .then((response)=>{
      return response.data;
    })
    .catch((error)=>{
      return rejectWithValue(error.response.data);
    })
  }
)

export const createPartCategory = createAsyncThunk(
    '/common/category/add',
    async(categoryInfo, {getState, rejectWithValue}) => {
      const state = getState();
      const token = state.userSignin.userInfo.access_token;
      const header = getHeader(token);
      return await axios.post(`${API_PREFIX}/api/admin/common/category`, categoryInfo , header)
      .then((response)=>{
        return response.data;
      })
      .catch((error)=>{
        return rejectWithValue(error.response.data);
      })
    }
)

export const updatePartCategory = createAsyncThunk(
  '/common/category/update',
  async(categoryInfo, {getState, rejectWithValue}) => {
    let id = categoryInfo.get('id');
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = getHeader(token);
    categoryInfo.append('_method', 'PUT');
    return await axios.post(`${API_PREFIX}/api/admin/common/category/${id}`, categoryInfo, header)
    .then((response)=>{
      return response.data;
    })
    .catch((error)=>{
      return rejectWithValue(error.response.data);
    })
  }
)

export const deletePartCategory = createAsyncThunk(
  '/common/category/delete/:id',
  async(categoryId, {getState, rejectWithValue}) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = getHeader(token);
    return await axios.delete(`${API_PREFIX}/api/admin/common/category/${categoryId}`, header)
    .then((response)=>{
      return response.data;
    })
    .catch((error)=>{
      return rejectWithValue(error.response.data);
    })
  }
)

/*---------------------Common Part--------------------*/ 

export const fetchCommonPartList = createAsyncThunk(
  '/common/get',
  async(arg, {getState, rejectWithValue}) => {
    const state = getState();
    const token = state.userSignin.userInfo.user.token;
    const header = getHeader(token);
    return await axios.get(`${API_PREFIX}/api/admin/common_list?key_search=${arg}`, header)
    .then((response) => {
      return response.data;
    })
    .catch((error)=>{
      return rejectWithValue(error.response.data);
    })
  }
)

export const getCommonPartById = createAsyncThunk(
'common/:id',
async(partID, {getState, rejectWithValue}) => {
  const state = getState();
  const token = state.userSignin.userInfo.access_token;
  const header = getHeader(token);
  return await axios.get(`${API_PREFIX}/api/admin/common/${partID}`,header)
  .then((response)=>{
    return response.data;
  })
  .catch((error)=>{
    return rejectWithValue(error.response.data);
  })
}
)

export const createCommonPart = createAsyncThunk(
  '/common/add',
  async(partInfo, {getState, rejectWithValue}) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = getHeader(token);
    return await axios.post(`${API_PREFIX}/api/admin/common`, partInfo , header)
    .then((response)=>{
      return response.data;
    })
    .catch((error)=>{
      return rejectWithValue(error.response.data);
    })
  }
)

export const updateCommonPart = createAsyncThunk(
'/common/update',
async(arg, {getState, rejectWithValue}) => {
  let id = arg.get('id');
  const state = getState();
  const token = state.userSignin.userInfo.access_token;
  const header = getHeader(token);
  arg.append('_method', 'PUT');
  return await axios.post(`${API_PREFIX}/api/admin/common/${id}`, arg, header)
  .then((response)=>{
    return response.data;
  })
  .catch((error)=>{
    return rejectWithValue(error.response.data);
  })
}
)

export const deleteCommonPart = createAsyncThunk(
'/common/delete/:id',
async(partID, {getState, rejectWithValue}) => {
  const state = getState();
  const token = state.userSignin.userInfo.access_token;
  const header = getHeader(token);
  return await axios.delete(`${API_PREFIX}/api/admin/common/${partID}`, header)
  .then((response)=>{
    return response.data;
  })
  .catch((error)=>{
    return rejectWithValue(error.response.data);
  })
}
)


