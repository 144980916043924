import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import ADMIN from "../../../api/ADMIN";

const api_prefix = `${process.env.REACT_APP_PREFIX_URL}`;
// const api_prefix = "${api_prefix}";

const methods = {
  get: 'GET',
  post: 'POST'
}

const axiosTemplate = async ({ state, rejectWithValue }, method, url, params, data) => {
  const token = state.userSignin.userInfo.user.token;
  let resp = await axios({
    method: method,
    url: `${api_prefix}${url}`,
    params: params,
    data: data,
    headers: {
      Authorization: `Bearer ${token && token}`
    }
  }).catch((error) => {
    return rejectWithValue(error.response);
  })
  return resp;
}

export const editUser = createAsyncThunk()

export const fetchUserList = createAsyncThunk(
  '/users',
  async (arg, { getState, rejectWithValue }) => {
    const { searchValue, currentPage, pageSize, sortBy, sortType } = arg;
    const state = getState();
    const method = methods.get,
      url = `/api/users?
      search=${searchValue ? searchValue : ''}
      &page=${currentPage ? currentPage : ''}
      &size=${pageSize ? pageSize : ""}
      &sort=${sortBy ? sortBy : ''}
      &sortType=${sortType ? sortType : ''}`,
      params = null,
      dataReq = null;
    const res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const signin = createAsyncThunk(
  '/',
  async (userInfo, { rejectWithValue }) => {
    const resp = await axios.post(`${api_prefix}/api/auth/login`, userInfo)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });

    if (resp.status > 400) {
      return rejectWithValue(resp.data);
    } else {
      return resp.data;
    }
  }
)

export const sendVerifyLogin = createAsyncThunk(
  '/verify-success',
  async (userInfo, { getState, rejectWithValue }) => {
    const state = getState();
    let resp = await axios({
      method: methods.get,
      url: `${api_prefix}${`/api/auth/email/verify/setLogin`}`,
      params: null,
      data: userInfo
    }).catch((error) => { return error.response })
    return resp.data
  }
)

export const signup = createAsyncThunk(
  '/signup',
  async (userInfo, { rejectWithValue }) => {
    const resp = await axios.post(`${api_prefix}/api/auth/signup`, userInfo)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      })

    if (resp.status > 400) {
      return rejectWithValue(resp.data);
    } else {
      return resp.data;
    }
  }
)

export const forgotPassword = createAsyncThunk(
  '/fotget-password',
  async (userInfo, { rejectWithValue }) => {
    const resp = await axios.post(`${api_prefix}/api/auth/forgotPassword`, userInfo)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      })
    if (resp.status > 400) {
      return rejectWithValue(resp.data);
    } else {
      return resp.data;
    }
  }
)

export const fetchUserDetail = createAsyncThunk(
  'users/:id',
  async (userId, { getState, rejectWithValue }) => {
    const state = getState();
    const method = methods.get,
      url = `/api/users/${userId}`,
      params = null,
      dataReq = null;
    const res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const saveUser = createAsyncThunk(

)

export const updateUser = createAsyncThunk(
  'users/:id/edit',
  async (arg, { getState, rejectWithValue }) => {
    if (arg !== null) {
      const state = getState();
      const { id, data } = arg
      const method = methods.post,
        url = `/api/users/${id}`,
        params = null,
        dataReq = data;
      const res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
      return res;
    }
  }
)

export const removeUser = createAsyncThunk(
  'users/:id/remove',
  async (arg, { getState, rejectWithValue }) => {
    const state = getState();
    const { id, searchValue, currentPage, pageSize, sortBy, sortType } = arg
    const method = methods.post,
      url = `/api/users/${id}/remove?
          search=${searchValue ? searchValue : ''}
          &page=${currentPage ? currentPage : ''}
          &size=${pageSize ? pageSize : ""}
          &sort=${sortBy}
          &sortType=${sortType}`,
      params = null,
      dataReq = null;
    const res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const changeStatus = createAsyncThunk(
  'users/change-status',
  async (arg, { getState, rejectWithValue }) => {
    const state = getState();
    const { id, status } = arg
    const method = methods.post,
      url = `/api/users/${id}/change-status`,
      params = null,
      dataReq = { status: status };
    const res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const fetchAdminAccountList = createAsyncThunk(
  '/admin_accounts',
  async (arg, { getState }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const { currentPage, pageSize } = state.accountList;
    const header = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    // const reponse =  await ADMIN.getAccount({
    //   pageSize,currentPage
    // });
    const reponse = await axios.get(`${api_prefix}/api/account?size=${pageSize ? pageSize : ''}&page=${currentPage ? currentPage : ''}`, header);
    return reponse.data;
  }
)


export const addAdmin = createAsyncThunk(
  '/admin_accounts/add',
  async (adminInfo, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    // return ADMIN.updateAccount(adminInfo)
    // .then((response)=>{
    //   return response.data;
    // })
    // .catch((error) => {
    //   return rejectWithValue(error.response.data);
    // });
    return await axios.post("${api_prefix}/api/account", adminInfo, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const fetchAdminAccount = createAsyncThunk(
  '/admin_accounts/:id',
  async (adminID, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.get(`${api_prefix}/api/account/${adminID}`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const updateAdminAccount = createAsyncThunk(
  '/admin_accounts/edit/:id',
  async (adminInfo, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.put(`${api_prefix}/api/account/${adminInfo.id}`, adminInfo, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const deleteAdminAccount = createAsyncThunk(
  '/admin_accounts/delete/:id',
  async (adminID, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.delete(`${api_prefix}/api/account/${adminID}`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const fetchUserProfile = createAsyncThunk(
  '/profile',
  async (userInfo, { getState, rejectWithValue }) => {
    const state = getState()
    const method = methods.get,
      url = `/api/profile`,
      params = null,
      dataReq = null;
    const res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const fetchUpdateUserProfile = createAsyncThunk(
  '/profile/:id/update',
  async (arg, { getState, rejectWithValue }) => {
    const state = getState();

    const method = methods.post,
      url = `/api/profile/${arg.id}/update`,
      params = null,
      dataReq = arg;
    const res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)


export const contact = createAsyncThunk(
  '/contact',
  async (arg, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.post(`${api_prefix}/api/contact`, arg, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)


export const logout = createAsyncThunk(
  '/logout',
  async (arg, { getState, rejectWithValue }) => {
    const state = getState();

    const method = methods.get,
      url = `/api/auth/logout`,
      params = null,
      dataReq = null;
    const res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)