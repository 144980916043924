/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { fetchTemplatesList, deleteTemplate, setTemplateUser } from './asyncThunk';

const initialState = {
  pageNum: 0,
  currentPage: 1,
  pageSize: 9,
  templatesList: [],
  template: [],
  page2D: [],
  pae360: [],
  total: 0,
  search: "",
  isLoading: false,
  error: null,
  success: null
}

const slice = createSlice({
  name: "templateList",
  initialState,
  reducers: {
    setCurrentPage: {
      reducer(state, action) {
        state.currentPage = action.payload.currentPage
      }
    },
    setPageSize: {
      reducer(state, action) {
        state.pageSize = Number(action.payload.pageSize)
      }
    }
  },
  extraReducers: (builder) => {
    // Template
    builder.addCase(fetchTemplatesList.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(fetchTemplatesList.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.currentPage = resp.current_page
      state.pageSize = Number(resp.per_page)
      state.totalPages = resp.last_page
      state.templatesList = resp;
    })
    builder.addCase(fetchTemplatesList.rejected, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.error = resp;
    })

    // delete template
    builder.addCase(deleteTemplate.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(deleteTemplate.fulfilled, (state, action) => {
      let resp = action.payload.data;
      state.isLoading = false;
      state.currentPage = resp.data.current_page
      state.pageSize = Number(resp.data.per_page)
      state.totalPages = resp.data.last_page
      state.templatesList = resp.data;
    })
    builder.addCase(deleteTemplate.rejected, (state, action) => {
      let resp = action.payload.data;
      state.isLoading = false;
      state.error = resp.data;
    })

    //set template user
    builder.addCase(setTemplateUser.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(setTemplateUser.fulfilled, (state, action) => {
      let resp = action.payload.data;
      state.isLoading = false;
      window.location.replace(`/user/exhibition/info/${resp.data.exhibition_id}`)
    })
    builder.addCase(setTemplateUser.rejected, (state, action) => {
      let resp = action.payload.data;
      state.isLoading = false;
      state.error = resp.data;
    })
    // *************************
  }
})

export const templateList = slice.reducer;
export const { setCurrentPage, setPageSize } = slice.actions;
