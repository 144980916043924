import './App.css';
import React, { lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AppRoute, PrivateRoute, PublicRoute } from "./helpers/router";

const Login = lazy(() => import('./pages/login/Login'));
const Signup = lazy(() => import('./pages/signup/Signup'));
const NotFound = lazy(() => import('./pages/404'));
const Layout = lazy(() => import('./containers/Layout'));
const HomePage = lazy(() => import('./pages/HomePage'));
const ForgetPassword = lazy(() => import('./pages/ForgotPassword'))
const LandingPage = lazy(() => import('./pages/LandingPage'))
const Policy = lazy(() => import('./pages/Policy'))
function App() {

  return (
    <>
      <Router>
        <Switch>
          <PublicRoute exact path="/admin/login" component={Login} />
          <PublicRoute exact path="/user/login" component={Login} />
          <PublicRoute exact path="/signup" component={Signup} />
          <PublicRoute exact path="/forgot-password" component={ForgetPassword} />


          <Route exact path="/" component={LandingPage}></Route>
          <Route exact path="/policy" component={Policy}></Route>
          <PrivateRoute path="/user" component={Layout} />
          <PrivateRoute path="/admin" component={Layout} />
          <AppRoute exact path="/:base/:url" component={HomePage} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
