/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";

import { fetchUserDetail, updateUser, fetchUserProfile, fetchUpdateUserProfile, removeUser, changeStatus } from './asyncThunk';

const emptyAccount = {
  id: null,
  first_name: '',
  last_name: '',
  company_name: '',
  email: '',
  tel: '',
  last_name_kana: '',
  first_name_kana: '',
  email_new: '',
  password_old: '',
  password_new: '',
  password_new_confirm: '',
  base_url: '',
  login: {
    role: ''
  },
  use_plan: 0,
}

const initialState = {
  account: emptyAccount,
  isLoading: false,
  successUpdate: false,
  error: '',
}

const slice = createSlice({
  name: "account",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetail.pending, (state, action) => {
      state.account = emptyAccount
      state.isLoading = true
    })
    builder.addCase(fetchUserDetail.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.account = resp
      state.isLoading = false
      state.successUpdate = false
      state.error = ''
    })
    builder.addCase(fetchUserDetail.rejected, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false
      state.error = resp.message
    })
    builder.addCase(updateUser.pending, (state, action) => {
      state.successUpdate = false
      state.isLoading = true
      state.error = ''
    })
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.account = emptyAccount
      state.successUpdate = true
      state.isLoading = false
      state.error = ''
    })
    builder.addCase(updateUser.rejected, (state, action) => {
      let resp = action.payload.data
      state.successUpdate = false
      state.error = resp
      state.isLoading = false
    })
    builder.addCase(fetchUserProfile.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false
      state.account = resp
      state.error = ''
    })
    builder.addCase(fetchUserProfile.rejected, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false
      state.error = resp.message
    })
    builder.addCase(fetchUpdateUserProfile.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(fetchUpdateUserProfile.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false
      state.account = resp
      state.successUpdate = true
      state.error = ''
    })
    builder.addCase(fetchUpdateUserProfile.rejected, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false
      state.error = resp.message
      state.successUpdate = false
    })
    builder.addCase(removeUser.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(removeUser.fulfilled, (state, action) => {
      state.account = emptyAccount
      state.successUpdate = true
      state.isLoading = false
      state.error = ''
      window.location.replace('/admin/users')
    })
    builder.addCase(removeUser.rejected, (state, action) => {
      let resp = action.payload.data
      state.error = resp.message
      state.isLoading = false
    })
    builder.addCase(changeStatus.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(changeStatus.fulfilled, (state, action) => {
      state.account = emptyAccount
      state.successUpdate = true
      state.isLoading = false
      state.error = ''
    })
    builder.addCase(changeStatus.rejected, (state, action) => {
      let resp = action.payload.data
      state.error = resp.message
      state.isLoading = false
    })
  }
})

export const userDetail = slice.reducer;
