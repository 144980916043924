/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { addAdmin, fetchAdminAccount, updateAdminAccount } from './asyncThunk';

const initialState = {
  pageNum: 0,
  pageSize: 10,
  adminAccount:[],
  selectedList: [],
  total: 0,
  search: "",
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: "adminAccountEdit",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(addAdmin.pending, (state, action) => {
      state.isLoading = true;
    }),
    builder.addCase(addAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      document.location.href = '/admin/admin_accounts';
    }),
    builder.addCase(addAdmin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    builder.addCase(fetchAdminAccount.pending, (state, action) => {
      state.isLoading = true;
    }),
    builder.addCase(fetchAdminAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.adminAccount = action.payload;
    }),
    builder.addCase(fetchAdminAccount.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    builder.addCase(updateAdminAccount.pending, (state, action) => {
      state.isLoading = true;
    }),
    builder.addCase(updateAdminAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      document.location.href = '/admin_accounts';
    }),
    builder.addCase(updateAdminAccount.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
  }
})

export const adminAccountEdit = slice.reducer;
