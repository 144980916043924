/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { fetchUserList, removeUser } from './asyncThunk';

const initialState = {
  totalPages: 0,
  currentPage: 1,
  pageSize: 10,
  userList: [],
  totalUser: 0,
  searchValue: '',
  isLoading: false,
  error: null,
  sortBy: "",
  sortType: ""
}

const slice = createSlice({
  name: "userList",
  initialState,
  reducers: {
    changeSelectedList: {
      reducer(state, action) {
        state.selectedList = action.payload.selectedList
      }
    },
    setCurrentPage: {
      reducer(state, action) {
        state.currentPage = action.payload.currentPage
      }
    },
    setPageSize: {
      reducer(state, action) {
        state.pageSize = action.payload.pageSize
      }
    },
    setSearch: {
      reducer(state, action) {
        state.searchValue = action.payload.searchValue
      }
    },
    setSortBy: {
      reducer(state, action) {
        state.sortBy = action.payload.sortBy
      }
    },
    setSortType: {
      reducer(state, action) {
        state.sortType = action.payload.sortType
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserList.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.totalUser = resp.total
      state.pageSize = Number(resp.per_page)
      state.totalPages = resp.last_page
      state.userList = resp.data
      state.isLoading = false
      state.error = ''

    })
    builder.addCase(fetchUserList.rejected, (state, action) => {
      let resp = action.payload.data
      state.userList = []
      state.selectedList = []
      state.error = resp.message
      state.isLoading = false
    })
    // builder.addCase(removeUser.pending, (state) => {
    //   state.isLoading = true;
    // })
    // builder.addCase(removeUser.fulfilled, (state, action) => {
    //   const response = action.payload.original
    //   state.currentPage = response.current_page
    //   state.totalUser = response.total
    //   state.pageSize = Number(response.per_page)
    //   state.totalPages = response.last_page
    //   state.userList = response.data
    //   state.isLoading = false
    //   state.error = {}
    // })
    // builder.addCase(removeUser.rejected, (state, action) => {
    //   state.error = action.payload.message
    //   state.isLoading = false
    // })
  }
})

export const userList = slice.reducer;
export const { setCurrentPage, setPageSize, setSearch, setSortBy, setSortType } = slice.actions;
