import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_prefix = `${process.env.REACT_APP_PREFIX_URL}`;


function getHeader(token){
  const header = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Authorization": `Bearer ${token && token}`,
    }
  };
  return header;
}

export const fetchExhibitionView = createAsyncThunk(
  '/exhibition/view/:base/:id',
  async (exh, { rejectWithValue }) => {
    return await axios.get(`${api_prefix}/api/exhibition/view/${exh.base}/${exh.url}`, null)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const fetchContentPageView = createAsyncThunk(
  '/exhibition/content_page_view/:id',
  async (exhId, { rejectWithValue }) => {
    return await axios.get(`${api_prefix}/api/exhibition/content_page/view/${exhId}`, null)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)


export const fetchExhibitionsList = createAsyncThunk(
  '/exhibition',
  async (arg, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const { currentPage, pageSize } = state.exhibitionList;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.get(`${api_prefix}/api/exhibition?size=${pageSize ? pageSize : ''}&page=${currentPage ? currentPage : ''}`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const fetchExhibition = createAsyncThunk(
  '/exhibition/:id',
  async (exhId, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.get(`${api_prefix}/api/exhibition/${exhId}`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const addExhibition = createAsyncThunk(
  '/exhibition/add',
  async (exhInfo, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.post(`${api_prefix}/api/exhibition`, exhInfo, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const updateExhibition = createAsyncThunk(
  '/exhibition/edit/:id',
  async (exhInfo, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.put(`${api_prefix}/api/exhibition/${exhInfo.id}`, exhInfo, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const changePublishExhibition = createAsyncThunk(
  '/exhibition/change_publish/:id',
  async (exhId, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.post(`${api_prefix}/api/exhibition/change_publish_exh/${exhId}`, null, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const fetchTopPage = createAsyncThunk(
  '/exhibition/top/exh/:id',
  async (exhId, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.get(`${api_prefix}/api/exhibition/top/exh/${exhId}`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const addTopPage = createAsyncThunk(
  '/exhibition/top:id',
  async (topInfo, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    let id = topInfo.get('id');
    return await axios.post(`${api_prefix}/api/exhibition/top/${id}`, topInfo, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)


export const changePublishTop = createAsyncThunk(
  '/exhibition/top/change_publish/:id',
  async (topInfo, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.post(`${api_prefix}/api/exhibition/top/change_publish_top/${topInfo.id}`, null, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const fetchTopPageByID = createAsyncThunk(
  '/exhibition/top/:id',
  async (exhId, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.get(`${api_prefix}/api/exhibition/top/${exhId}`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)


export const updateTopPage = createAsyncThunk(
  '/exhibition/top/edit/:id',
  async (exhInfo, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`,
      }
    };
    exhInfo.append('_method', 'PUT');
    let id = exhInfo.get('id');
    return await axios.post(`${api_prefix}/api/exhibition/top/${id}`, exhInfo, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)




export const addContentPage = createAsyncThunk(
  '/exhibition/:id/content_page/',
  async (ContentInfo, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    let id = ContentInfo.get('id');
    return await axios.post(`${api_prefix}/api/exhibition/content_page/${id}`, ContentInfo, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)



export const updateContentPage = createAsyncThunk(
  '/exhibition/content_page/edit/:id',
  async (contentInfo, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    contentInfo.append('_method', 'PUT');
    let id = contentInfo.get('id');
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.post(`${api_prefix}/api/exhibition/content_page/${id}`, contentInfo, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)


export const fetchContentPageByExh = createAsyncThunk(
  '/exhibition/content_page/exh/:id',
  async (exhId, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.get(`${api_prefix}/api/exhibition/content_page/exh/${exhId}`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const fetchContentPageByID = createAsyncThunk(
  '/exhibition/content_page/:id',
  async (contentId, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.get(`${api_prefix}/api/exhibition/content_page/${contentId}`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const fetchPageIDForButtonContent = createAsyncThunk(
  '/exhibition/content_page/page_list/:id',
  async (exh, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.get(`${api_prefix}/api/exhibition/content_page/page_list/${exh.id}/${exh.type}`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const deletePage = createAsyncThunk(
  '/exhibition/:id/deletePage',
  async (idDelete, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    let exhId = idDelete.get('exh_id');
    idDelete.append('_method', 'DELETE');
    return await axios.post(`${api_prefix}/api/exhibition/${exhId}/deletePage`, idDelete, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const deleteExhibition = createAsyncThunk(
  '/exhibition/delete',
  async (idArr, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const { currentPage, pageSize } = state.exhibitionList;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    idArr.append('_method', 'DELETE');
    return await axios.post(`${api_prefix}/api/exhibition?size=${pageSize ? pageSize : ''}&page=${currentPage ? currentPage : ''}`, idArr, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)


export const setHome = createAsyncThunk(
  '/exhibition/set_home',
  async (idArr, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = getHeader(token);
    return await axios.post(`${api_prefix}/api/exhibition/set_home`, idArr, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const fetchPageAccess = createAsyncThunk(
  '/content_page/{id}',
  async (args, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.userSignin.userInfo.access_token;
    const header = getHeader(token);
    let id = args.get('id');
    let from = args.get('dateFrom');
    let to = args.get('dateTo');
    const options = {
      ...header,
      params: { from, to }
    }
    return await axios.get(`${api_prefix}/api/access/content_page/${id}`, options)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
)

export const fetchPageAccessByID = createAsyncThunk(
  '/content_page/{id}/button',
  async (args, { getState, rejectWithValue }) => {
    const state = getState()
    const token = state.userSignin.userInfo.access_token;
    const header = getHeader(token);
    let id = args.get('id');
    let pageType = args.get('pageType');
    let from = args.get('dateFrom');
    let to = args.get('dateTo');
    const options = {
      ...header,
      params: { from, to }
    }
    return await axios.get(`${api_prefix}/api/access/content_page/button/${id}/${pageType}`, options)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      })
  }
)

export const logAccessPage = createAsyncThunk(
  '/homepage/accessPage',
  async (args, { rejectWithValue }) => {
    return await axios.post(`${api_prefix}/api/count/accessPage`, args)
      .then((response) => {
        return response.message;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      })
  }
)

export const logActionCount = createAsyncThunk(
  '/homepage/button/{id}',
  async (id, { rejectWithValue }) => {
    return await axios.post(`${api_prefix}/api/count/button/${id}`)
      .then((response) => {
        return response.message;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      })
  }
)

export const fetchTemplatePreview = createAsyncThunk(
  '/exhibition/template/{id}',
  async (id, { getState, rejectWithValue }) => {
    const state = getState()
    const token = state.userSignin.userInfo.access_token;
    const header = {
      headers: {
        Authorization: `Bearer ${token && token}`
      }
    };
    return await axios.get(`${api_prefix}/api/exhibition/template/${id}`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      })
  }
)
