/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { addExhibition, addTopPage, changePublishExhibition, changePublishTop, deletePage, fetchPageIDForButtonContent, fetchContentPageByExh, fetchContentPageByID, fetchExhibition, fetchTopPage, fetchTopPageByID, setHome, updateExhibition, updateTopPage } from './asyncThunk';

const initialState = {
  exhibition: [],
  topPage: [],
  contentPage: [],
  orderNumList: [],
  pageOrderNumber: 0,
  total: 0,
  search: "",
  isLoading: false,
  error: null,
  success: null,
  template: []
}

const slice = createSlice({
  name: "exhibitionAddEdit",
  initialState,
  reducers: {
    clearStateError: {
      reducer(state, action) {
        state.error = initialState.error
      }
    },
  },
  extraReducers: (builder) => {

    // Exhibitions
    builder.addCase(fetchExhibition.pending, (state, action) => {
      state.isLoading = true;
      state.exhibition = [];
      state.error = initialState.error
    })
    builder.addCase(fetchExhibition.fulfilled, (state, action) => {
      state.isLoading = false;
      state.exhibition = action.payload;
      state.error = null;
    })
    builder.addCase(fetchExhibition.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    builder.addCase(addExhibition.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(addExhibition.fulfilled, (state, action) => {
      state.isLoading = false;
      document.location.href = '/user/exhibition/info/' + action.payload.data.id;
      state.error = initialState.error;
    })
    builder.addCase(addExhibition.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    builder.addCase(updateExhibition.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(updateExhibition.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      document.location.href = '/user/exhibition/info/' + action.payload.data.id;
    })
    builder.addCase(updateExhibition.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })


    builder.addCase(changePublishExhibition.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(changePublishExhibition.fulfilled, (state, action) => {
      state.isLoading = false;
      state.exhibition = action.payload;
    })
    builder.addCase(changePublishExhibition.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // *************************


    // Top page

    builder.addCase(addTopPage.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(addTopPage.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.data.page_type == 1) {
        document.location.href = '/user/exhibition/info/' + action.payload.data.exhibition_id;
      } else {
        document.location.href = '/admin/template/' + action.payload.data.exhibition_id;
      }
    })
    builder.addCase(addTopPage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })


    builder.addCase(updateTopPage.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(updateTopPage.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.data.page_type == 1) {
        document.location.href = '/user/exhibition/info/' + action.payload.data.exhibition_id;
      } else {
        document.location.href = '/admin/template/' + action.payload.data.exhibition_id;
      }
    })
    builder.addCase(updateTopPage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })


    builder.addCase(changePublishTop.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(changePublishTop.fulfilled, (state, action) => {
      state.isLoading = false;
      state.topPage = action.payload;
    })
    builder.addCase(changePublishTop.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })



    // Top page
    builder.addCase(fetchTopPage.pending, (state, action) => {
      state.isLoading = true;
      state.topPage = [];
    })
    builder.addCase(fetchTopPage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.topPage = action.payload;
    })
    builder.addCase(fetchTopPage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.topPage = [];
    })


    builder.addCase(fetchTopPageByID.pending, (state, action) => {
      state.isLoading = true;
      state.topPage = [];
    })
    builder.addCase(fetchTopPageByID.fulfilled, (state, action) => {
      state.isLoading = false;
      state.topPage = action.payload;
    })
    builder.addCase(fetchTopPageByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.topPage = [];
    })


    // Content Page
    builder.addCase(fetchContentPageByExh.pending, (state, action) => {
      state.isLoading = true;
      state.contentPage = [];
    })
    builder.addCase(fetchContentPageByExh.fulfilled, (state, action) => {
      state.isLoading = false;
      state.contentPage = action.payload;
    })
    builder.addCase(fetchContentPageByExh.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.contentPage = [];
    })


    builder.addCase(fetchContentPageByID.pending, (state, action) => {
      state.isLoading = true;
      state.contentPage = [];
    })
    builder.addCase(fetchContentPageByID.fulfilled, (state, action) => {
      state.isLoading = false;
      state.contentPage = action.payload;
    })
    builder.addCase(fetchContentPageByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.contentPage = [];
    })


    builder.addCase(fetchPageIDForButtonContent.pending, (state, action) => {
      state.isLoading = true;
      state.orderNumList = [];
    })
    builder.addCase(fetchPageIDForButtonContent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orderNumList = action.payload.data;
      state.pageOrderNumber = action.payload.count;

    })
    builder.addCase(fetchPageIDForButtonContent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.orderNumList = [];
    })
    // *************************


    // delete top page and content page from exhibition info screen
    builder.addCase(deletePage.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(deletePage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.exhibition = action.payload;
    })
    builder.addCase(deletePage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    // set home
    builder.addCase(setHome.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(setHome.fulfilled, (state, action) => {
      state.isLoading = false;
      state.exhibition = action.payload;
    })
    builder.addCase(setHome.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    // *************************
  }
})

export const exhibitionAddEdit = slice.reducer;
export const { setCurrentPage, setPageSize, clearStateError } = slice.actions;
