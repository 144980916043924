import { createSlice } from "@reduxjs/toolkit";

import {
    fetchCommonPartList,
    getCommonPartById,
    createCommonPart,
    updateCommonPart,
    deleteCommonPart
} from './asyncThunk'

const initialState = {
    commonPartList: [],
    isComPartLoading: false,
    error: null,
}

const slice = createSlice({
    name: "commonPartList",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchCommonPartList.pending, (state) => {
            state.isComPartLoading = true;
          })
          builder.addCase(fetchCommonPartList.fulfilled, (state, action) => {
            state.isComPartLoading = false;
            state.commonPartList = action.payload.data;
          })
          builder.addCase(fetchCommonPartList.rejected, (state, action) => {
            state.isComPartLoading = false;
            state.error = action.payload;
          })
          
          // create part category
          builder.addCase(createCommonPart.pending, (state) => {
            state.isComPartLoading = true;
          })
          builder.addCase(createCommonPart.fulfilled, (state, action) => {
            state.isComPartLoading = false;
            state.commonPartList = action.payload.data;
          })
          builder.addCase(createCommonPart.rejected, (state, action) => {
            state.isComPartLoading = false;
            state.error = action.payload;
          })
    
          // update part category
          builder.addCase(updateCommonPart.pending, (state) => {
            state.isComPartLoading = true;
          })
          builder.addCase(updateCommonPart.fulfilled, (state, action) => {
            state.isComPartLoading = false;
            state.commonPartList = action.payload.data;
          })
          builder.addCase(updateCommonPart.rejected, (state, action) => {
            state.isComPartLoading = false;
            state.error = action.payload;
          })
    
          // delete part category
          builder.addCase(deleteCommonPart.pending, (state) => {
            state.isComPartLoading = true;
          })
          builder.addCase(deleteCommonPart.fulfilled, (state, action) => {
            state.isComPartLoading = false;
            state.commonPartList = action.payload.data;
          })
          builder.addCase(deleteCommonPart.rejected, (state, action) => {
            state.isComPartLoading = false;
            state.error = action.payload;
          })
    }
})

export const commonPart = slice.reducer;

// export const {} = slice.actions;