import { createSlice } from "@reduxjs/toolkit";

import {
    fetchPartCategoryList,
    getPartCategoryById,
    createPartCategory,
    updatePartCategory,
    deletePartCategory
  } from './asyncThunk'

const initialState = {
    categoryList: [],
    isLoading: false,
    error: null,
};

const slice = createSlice({
    name: "categoryList",
    initialState,
    reducers:{

    },
    extraReducers: (builder) =>{
      // fectch part category list
      builder.addCase(fetchPartCategoryList.pending, (state) => {
        state.isLoading = true;
      })
      builder.addCase(fetchPartCategoryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categoryList = action.payload.data;
      })
      builder.addCase(fetchPartCategoryList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      
      // create part category
      builder.addCase(createPartCategory.pending, (state) => {
        state.isLoading = true;
      })
      builder.addCase(createPartCategory.fulfilled, (state, action) => {
        state.categoryList = action.payload.data;
        state.isLoading = false;
      })
      builder.addCase(createPartCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // update part category
      builder.addCase(updatePartCategory.pending, (state) => {
        state.isLoading = true;
      })
      builder.addCase(updatePartCategory.fulfilled, (state, action) => {
        state.categoryList = action.payload.data;
        state.isLoading = false;
      })
      builder.addCase(updatePartCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // delete part category
      builder.addCase(deletePartCategory.pending, (state) => {
        state.isLoading = true;
      })
      builder.addCase(deletePartCategory.fulfilled, (state, action) => {
        state.categoryList = action.payload.data;
        state.isLoading = false;
      })
      builder.addCase(deletePartCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
    }
})

export const partCategory = slice.reducer; 

// export const {} = slice.action;