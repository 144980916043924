import { createSlice } from "@reduxjs/toolkit";
import {
  addContentPage,
  updateContentPage,
  fetchContentPageByID,
} from "../exhibitionSlice/asyncThunk";

const emptyContentPage = {
  page_title: "Page",
  camera_pos_x: 0.9,
  camera_pos_y: 0.4,
  camera_pos_z: 0.09,
  camera_fov: 70,
  main_image: null,
  exhibitionId: null,
  publish: 0,
  active: 0,
  content_button: [],
  error: null,
};

const initialState = {
  contentApi: emptyContentPage,
  contentPreview: emptyContentPage,
  orderNumList: [],
  isLoading: false,
};

const slice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setPageTitle: {
      reducer(state, action) {
        state.contentApi.page_title = action.payload.page_title;
      },
    },
    setCameraPosition: {
      reducer(state, action) {
        state.contentApi.camera_pos_x = action.payload.camera_pos_x;
        state.contentApi.camera_pos_y = action.payload.camera_pos_y;
        state.contentApi.camera_pos_z = action.payload.camera_pos_z;
        state.contentApi.camera_fov = action.payload.camera_fov;
      },
    },
    setPanoImage: {
      reducer(state, action) {
        // state.contentApi.main_image = action.payload.main_image;
        state.contentApi.page_title = action.payload.page_title;
      },
    },
    setPreviewPanoImage: {
      reducer(state, action) {
        state.contentPreview.main_image = action.payload.main_image;
      },
    },
    setButton: {
      reducer(state, action) {
        state.contentApi.content_button = action.payload.content_button;
      },
    },
    setPreviewButton: {
      reducer(state, action) {
        state.contentPreview.content_button = action.payload.content_button;
      },
    },
    resetState: {
      reducer() {
        return initialState;
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addContentPage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addContentPage.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.data.page_type == 1) {
        document.location.href = "/user/exhibition/info/" + action.payload.data.exhibition_id;
      } else {
        document.location.href = "/admin/template/" + action.payload.data.exhibition_id;
      }
    });
    builder.addCase(addContentPage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(fetchContentPageByID.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchContentPageByID.fulfilled, (state, action) => {
      state.contentApi = action.payload.data;
      state.contentPreview = emptyContentPage;
      state.isLoading = false;
    });
    builder.addCase(fetchContentPageByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(updateContentPage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateContentPage.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.data.page_type == 1) {
        document.location.href = "/user/exhibition/info/" + action.payload.data.exhibition_id;
      } else {
        document.location.href = "/admin/template/" + action.payload.data.exhibition_id;
      }
    });
    builder.addCase(updateContentPage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

  },
});

export const contentPage = slice.reducer;

export const {
  setPageTitle,
  setCameraPosition,
  setPanoImage,
  setPreviewPanoImage,
  setButton,
  setPreviewButton,
  resetState,
} = slice.actions;
