/**
    * Create cookie
    *
    * @param  [string] name
    * @param  [string] value
    * @param  [string] day
    */
export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(exdays * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/**
    * Get cookie
    *
    * @param  [string] name
    * @return [string] cookie
    */
export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/**
    * Check cookie
    *
    * @param  [string] name
    */
export function checkCookie(name) {
    let user = getCookie(name);
    if (user != "") {
        console.log("Welcome again " + user);
    } else {
        user = prompt("Please enter your name:", "");
        if (user != "" && user != null) {
            setCookie(name, user, 365);
        }
    }
}

/**
    * Clear cookie
    *
    */
export function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}