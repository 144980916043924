import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_prefix = `${process.env.REACT_APP_PREFIX_URL}`;

const methods = {
  get: 'GET',
  post: 'POST',
  put: 'PUT'
}

const axiosTemplate = async ({ state, rejectWithValue }, method, url, params, data) => {
  const token = state.userSignin.userInfo.user.token;
  let resp = await axios({
    method: method,
    url: `${api_prefix}${url}`,
    params: params,
    data: data,
    headers: {
      Authorization: `Bearer ${token && token}`
    }
  }).catch((error) => {
    return rejectWithValue(error.response);
  })
  return resp;
}

export const fetchTemplatesList = createAsyncThunk(
  '/template',
  async (arg, { getState, rejectWithValue }) => {
    const state = getState();
    const { currentPage, pageSize } = state.templateList;
    const method = methods.get,
      url = `/api/admin/template?size=${pageSize ? pageSize : ''}&page=${currentPage ? currentPage : ''}&publish=${arg.publish ? arg.publish : ''}`,
      params = null,
      dataReq = null;
    let res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const deleteTemplate = createAsyncThunk(
  '/template/delete',
  async (idArr, { getState, rejectWithValue }) => {
    const state = getState();
    idArr.append('_method', 'DELETE');
    const { currentPage, pageSize } = state.templateList;
    const method = methods.post,
      url = `/api/admin/template?size=${pageSize ? pageSize : ''}&page=${currentPage ? currentPage : ''}`,
      params = null,
      dataReq = idArr;
    let res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const fetchTemplate = createAsyncThunk(
  '/template/:id',
  async (tempId, { getState, rejectWithValue }) => {
    const state = getState();
    const method = methods.get,
      url = `/api/admin/template/${tempId}`,
      params = null,
      dataReq = null;
    let res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const addTemplate = createAsyncThunk(
  '/template/add',
  async (tempInfo, { getState, rejectWithValue }) => {
    const state = getState();
    const method = methods.post,
      url = `/api/admin/template`,
      params = null,
      dataReq = tempInfo;
    let res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const updateTemplate = createAsyncThunk(
  '/template/edit/:id',
  async (tempInfo, { getState, rejectWithValue }) => {
    const state = getState();
    const method = methods.post,
      url = `/api/admin/template/${tempInfo.id}`,
      params = null,
      dataReq = tempInfo;
    let res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const changePublishTemplate = createAsyncThunk(
  '/template/change_publish/:id',
  async (id, { getState, rejectWithValue }) => {
    const state = getState();
    const method = methods.post,
      url = `/api/admin/template/change_publish/${id}`,
      params = null,
      dataReq = null;
    let res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const deletePage = createAsyncThunk(
  '/template/:id/deletePage',
  async (idDelete, { getState, rejectWithValue }) => {
    const state = getState();
    let tempId = idDelete.get('exh_id');
    idDelete.append('_method', 'DELETE');
    const method = methods.post,
      url = `/api/exhibition/${tempId}/deletePage`,
      params = null,
      dataReq = idDelete;
    let res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res.data;
  }
)

export const deletePageTemplate = createAsyncThunk(
  '/template/:id/deletePageTemplate',
  async (idDelete, { getState, rejectWithValue }) => {
    const state = getState();
    let tempId = idDelete.get('exh_id');
    idDelete.append('_method', 'DELETE');
    const method = methods.post,
      url = `/api/admin/template/${tempId}/deletePage`,
      params = null,
      dataReq = idDelete;
    let res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const setTemplateUser = createAsyncThunk(
  '/template/set_template',
  async (tempId, { getState, rejectWithValue }) => {
    const state = getState();
    const method = methods.post,
      url = `/api/exhibition/setTemplateUser`,
      params = null,
      dataReq = tempId;
    let res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)

export const setHomeTemplate = createAsyncThunk(
  '/template/setHome',
  async (idArr, { getState, rejectWithValue }) => {
    const state = getState();
    const id = idArr.get('exh_id');
    const method = methods.post,
      url = `/api/admin/template/${id}/setHome`,
      params = null,
      dataReq = idArr;
    let res = await axiosTemplate({ state, rejectWithValue }, method, url, params, dataReq);
    return res;
  }
)