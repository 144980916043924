/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { fetchContentPageView, fetchExhibitionView, fetchTemplatePreview } from "./asyncThunk";

const initialState = {
  exhibition: [],
  contentPage: [],
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: "exhibitionView",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExhibitionView.pending, (state, _action) => {
      state.isLoading = true;
    })
    builder.addCase(fetchExhibitionView.fulfilled, (state, action) => {
      state.exhibition = action.payload.data
      state.isLoading = false
    })
    builder.addCase(fetchExhibitionView.rejected, (state, _action) => {
      state.error = "Can not fetch Data"
      state.isLoading = false
    })

    builder.addCase(fetchContentPageView.pending, (state, _action) => {
      state.isLoading = true;
    })
    builder.addCase(fetchContentPageView.fulfilled, (state, action) => {
      state.contentPage = action.payload.data
      state.isLoading = false
    })
    builder.addCase(fetchContentPageView.rejected, (state, _action) => {
      state.error = "Can not fetch Data"
      state.isLoading = false
    })

    // get Template
    builder.addCase(fetchTemplatePreview.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(fetchTemplatePreview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.exhibition = action.payload.data;
    })
    builder.addCase(fetchTemplatePreview.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
  }
})

export const exhibitionView = slice.reducer;
