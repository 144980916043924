/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { addTemplate, fetchTemplate, updateTemplate, deletePage, changePublishTemplate, deletePageTemplate, setHomeTemplate } from './asyncThunk';

const initialState = {
  template: [],
  total: 0,
  search: "",
  isLoading: false,
  error: null,
  success: null
}

const slice = createSlice({
  name: "templateAddEdit",
  initialState,
  reducers: {
    clearStateError: {
      reducer(state, action) {
        state.error = initialState.error
      }
    }
  },
  extraReducers: (builder) => {

    // Templates
    builder.addCase(fetchTemplate.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(fetchTemplate.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.template = resp;
      state.error = initialState.error;
    })
    builder.addCase(fetchTemplate.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    builder.addCase(addTemplate.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(addTemplate.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      document.location.href = '/admin/template/' + resp.data.id;
      state.error = initialState.error;
    })
    builder.addCase(addTemplate.rejected, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.error = resp;
    })


    builder.addCase(updateTemplate.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(updateTemplate.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.error = initialState.error;
      document.location.href = '/admin/template/' + resp.data.id;
    })
    builder.addCase(updateTemplate.rejected, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.error = resp;
    })


    builder.addCase(changePublishTemplate.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(changePublishTemplate.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.template = resp;
      state.error = initialState.error;
    })
    builder.addCase(changePublishTemplate.rejected, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.error = resp;
    })

    // delete top page and content page from exhibition info screen
    builder.addCase(deletePage.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(deletePage.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.template = resp;
      state.error = initialState.error;
    })
    builder.addCase(deletePage.rejected, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.error = resp;
    })

    // delete top page and content page from template info screen
    builder.addCase(deletePageTemplate.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(deletePageTemplate.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.template = resp;
      state.error = initialState.error;
    })
    builder.addCase(deletePageTemplate.rejected, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.error = resp;
    })

    // Set home top page and content page from template info screen
    builder.addCase(setHomeTemplate.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(setHomeTemplate.fulfilled, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.template = resp;
      state.error = initialState.error;
    })
    builder.addCase(setHomeTemplate.rejected, (state, action) => {
      let resp = action.payload.data
      state.isLoading = false;
      state.error = resp;
    })
    // *************************
  }
})

export const templateAddEdit = slice.reducer;
export const { setCurrentPage, setPageSize, clearStateError } = slice.actions;
